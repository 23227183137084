<template>
  <div class="col main_institutions_wrapper">
    <!-- Institutions table  -->

    <!-- //? start Large Screens  -->
    <server-table
      class="hidden-sm-only hidden-xs-only"
      :count="$store.getters['organization/total']"
      :DataItems="$store.getters['organization/organizations']"
      @Refresh="Refresh"
      @export="Export"
      :filter="filter"
      @reset="filter = {}"
      import_url="organizations/import"
      :loading="$store.getters['organization/usersLoad']"
      :title="`${localization('Institutions')}`"
      admin_permission="admin_institutions"
      edit_permission="add_institutions"
    >
      <template #columns>
        <el-table-column
          prop="id"
          label="#"
          width="50"
          sortable
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="`${localization('Name')}`"
          width="200"
          sortable
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="max_users"
          :label="`${localization('Total Seats')}`"
          width="100"
          sortable
        ></el-table-column>
        <el-table-column
          prop="remaining_seats"
          :label="`${localization('Remaining')}`"
          width="100"
          sortable
        ></el-table-column>
        <!-- ABE columns  -->
        <el-table-column
          prop="abe"
          :label="`${localization('ABE')}`"
          width="50"
          sortable
        >
          <template slot-scope="scope">
            <span
              class="ape no-ape"
              :class="{ 'is-ape': scope.row.abe == 1 }"
            ></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="promo_code"
          :label="`${localization('Code')}`"
          sortable
        ></el-table-column>

        <el-table-column
          prop="email"
          :label="`${localization('Contact INFO')}`"
          min-width="200"
        >
          <template slot-scope="scope">
            <div>
              <span class="d-block text-muted">
                {{ scope.row.contact_name }}</span
              >
              <span class="d-block text-muted">
                {{ scope.row.phone_number }}</span
              >
              <span class="d-block text-muted"> {{ scope.row.email }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="category_names"
          :label="`${localization('Category')}`"
          min-width="200"
        >
          <template slot-scope="scope">
            <div v-for="item in scope.row.category_names" :key="item.id">
              <span class=" text-muted"> {{ item.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="expiration_date"
          width="120"
          label="remaining seats"
          sortable
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              "
            >
              {{ toLocalDatetime(scope.row.expiration_date, "en") }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="removed"
          :label="`${$t('table.State')}`"
          width="90"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 5px"
            >
              {{
                scope.row.removed == 0
                  ? `${$t("institutions.active")}`
                  : `${$t("institutions.inactive")}`
              }} </l-button
            >&nbsp;
          </template>
        </el-table-column> -->

        <!-- Start Institutions Actions Add Or Edit Or Delete -->
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <router-link
                v-if="hasPermission('show_institutions')"
                :to="`/show-institution/${scope.row.id}`"
                v-tooltip.top-center="`${localization('Show Institution')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i
              ></router-link>
              <a
                v-if="hasPermission('edit_institutions')"
                v-tooltip.top-center="`${localization('Edit Institution')}`"
                @click="open_institution_dialog(2, scope.row)"
                class="btn btn-warning btn-link btn-xs"
              >
                <i class="fa fa-edit"></i>
              </a>

              <a
                v-if="hasPermission('edit_institutions')"
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete Institution')}`
                    : `${localization('Activate Institution')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
            </div>
          </template>
        </el-table-column>
        <!-- End Institutions Actions Add Or Edit Or Delete -->
      </template>

      <template #action>
        <div @click="open_institution_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Institution") }}
        </div>
      </template>
    </server-table>
    <!-- //? end Large Screens  -->

    <!-- //// start Small & Medium Screens  -->
    <server-table
      class="hidden-md-only hidden-lg-only hidden-xl-only"
      :count="$store.getters['organization/total']"
      :DataItems="$store.getters['organization/organizations']"
      @Refresh="Refresh"
      @export="Export"
      :filter="filter"
      @reset="reset_filter"
      import_url="organizations/import"
      :loading="$store.getters['organization/usersLoad']"
      :title="`${localization('Institutions')}`"
      admin_permission="admin_institutions"
      edit_permission="add_institutions"
    >
      <template #columns>
        <el-table-column prop="id" label="#" width="70"></el-table-column>
        <el-table-column prop="name" :label="`${localization('Name')}`">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                width: 100%;
              "
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-sm-between
                align-items-sm-center
              "
            >
              {{ scope.row.name }}
              <div>
                <router-link
                  v-if="hasPermission('show_institutions')"
                  :to="`/show-institution/${scope.row.id}`"
                  v-tooltip.top-center="`${localization('Show Institution')}`"
                  class="btn btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye"></i
                ></router-link>
                <a
                  v-if="hasPermission('edit_institutions')"
                  v-tooltip.top-center="`${localization('Edit Institution')}`"
                  @click="open_institution_dialog(2, scope.row)"
                  class="btn btn-warning btn-link btn-xs"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  v-if="hasPermission('edit_institutions')"
                  v-tooltip.top-center="
                    scope.row.removed == 0
                      ? `${localization('Delete Institution')}`
                      : `${localization('Activate Institution')}`
                  "
                  @click="remove(scope.row.id, scope.row.removed)"
                  :class="
                    scope.row.removed == 0
                      ? `btn btn-danger btn-link btn-xs`
                      : `btn btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                  <i v-else class="fa fa-check"></i>
                </a>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #action>
        <div @click="open_institution_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Institution") }}
        </div>
      </template>
    </server-table>
    <!-- //! end Small & Medium Screens  -->

    <!-- //? start  Add or Edit Institution  -->
    <el-dialog
      :title="`${localization('Institutions')}`"
      :visible.sync="institution_dialog"
      top="4vh"
      width="90%"
    >
      <el-row :gutter="10">
        <el-col class="my-4 part_one_card" :xs="24" :md="12">
          <el-form
            :model="institution"
            ref="institutionRef"
            :rules="institutionRules"
            label-position="top"
          >
            <el-card class="mr-2 mb-3">{{
              localization("Edit Institutions Info")
            }}</el-card>
            <el-col :span="24">
              <el-form-item :label="`${localization('Name')}`" prop="name">
                <el-input v-model="institution.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item
                :label="`${localization('Promo Code')}`"
                prop="promo_code"
              >
                <el-input v-model="institution.promo_code"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item
                :label="`#${localization('Of Seats')}`"
                prop="max_users"
              >
                <el-input v-model="institution.max_users"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`#${localization('Expiration Date')}`"
                prop="expiration_date"
              >
                <el-date-picker
                  v-model="institution.expiration_date"
                  format="dd / MM / yyyy"
                  value-format="yyyy-MM-dd"
                  :picker-options="datePickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="`#${localization('Notes')}`">
                <el-input v-model="institution.notes"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`#${localization('Contact Name')}`"
                prop="contact_name"
              >
                <el-input v-model="institution.contact_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item :label="`${localization('Email')}`" prop="email">
                <el-input v-model="institution.email"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item
                :label="`${localization('Phone')}`"
                prop="phone_number"
              >
                <el-input v-model="institution.phone_number"></el-input>
              </el-form-item>
            </el-col>
            <!-- Categories Select Box  -->
            <el-col :span="24">
              <el-form-item :label="`${localization('Category')}`" prop="">
                <el-select
                  v-model="institution.task_category_id"
                  :placeholder="`${localization('Please select category')}`"
                  style="width: 100%"
                  multiple
                >
                  <el-option
                    v-for="item in categories"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- APE  -->
            <el-col :md="12" :xs="24">
              <el-form-item
                :label="`${localization('ABE')}`"
                prop="abe"
                style="line-height:normal"
              >
                <!-- <el-input v-model="institution.abe"></el-input> -->
                <el-checkbox v-model="institution.abe"></el-checkbox>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <div class="dialog_footer">
                <el-button
                  class="text-center text-capitalize save_button delete_button"
                  @click="institution_dialog = false"
                >
                  {{ localization("Cancel") }}
                </el-button>
                <el-button
                  class="text-center text-capitalize save_button"
                  @click="save"
                >
                  {{ localization("Save") }}</el-button
                >
              </div>
            </el-col>
            <!--            <el-divider direction="vertical"></el-divider>-->
          </el-form>
        </el-col>

        <!-- //? Start Payment  -->
        <el-col class="my-4 part_two_card" :xs="24" :md="12">
          <el-form
            :model="institution_payment"
            ref="paymentRef"
            label-position="top"
            class=""
          >
            <el-card
              class="mr-2 mb-3"
              style="
                width: 100%;
              "
            >
              {{ localization("Make A Payment") }}
            </el-card>
            <el-col :span="24">
              <el-form-item
                :label="`${localization('Card Holder Name')}`"
                label-width="180px"
              >
                <el-input v-model="institution_payment.card_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`${localization('Credit Card Number')}`"
                label-width="180px"
              >
                <el-input
                  v-model="institution_payment.card_number"
                  maxlength="16"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item
                :label="`${localization('Credit Card Expiration Date')}`"
                label-width="180px"
              >
                <!-- changed prop name to not match expiration date of institution  -->
                <el-date-picker
                  v-model="institution_payment.exp_time"
                  format="MM/yy"
                  value-format="yyyy-MM"
                  type="month"
                  :picker-options="datePickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`${localization('CVC')}`"
                label-width="180px"
              >
                <el-input
                  v-model="institution_payment.cvc_code"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`$${localization('Amount')}`"
                label-width="180px"
              >
                <el-input v-model="institution_payment.amount"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="">
              <div
                class="
                  dialog-footer
                  dialog_footer
                "
              >
                <el-button
                  class="text-center text-capitalize save_button delete_button"
                  @click="institution_dialog = false"
                >
                  {{ localization("Cancel") }}
                </el-button>
                <el-button
                  type=""
                  class="
                    text-center
                    text-capitalize
                    save_button
                  "
                  @click="processPayment"
                >
                  {{ localization("Submit") }}
                </el-button>
              </div>
            </el-col>
          </el-form>
        </el-col>
        <!-- //? End Payment  -->
      </el-row>
    </el-dialog>
    <!-- //? end Add or Edit Institution  -->

    <!-- //// start Show institution dialog on medium & small screens  -->
    <el-dialog
      :title="`${localization('Institutions')}`"
      :visible.sync="show_institution_dialog"
      top="4vh"
      width="90%"
    >
      <Info :items="shown_institution" />
      <div
        class="
          dialog-footer
          flex-column flex-xl-row
          w-50
          d-lg-flex
          my-3
          ml-auto
        "
      >
        <el-button
          type="danger"
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="show_institution_dialog = false"
        >
          {{ localization("Cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- //// end Show institution dialog on medium & small screens  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Info from "../../components/Cards/Info";
import Swal from "sweetalert2";
import "element-ui/lib/theme-chalk/display.css";

export default {
  name: "index",
  components: { ServerTable, Info },
  data() {
    return {
      filter: {},
      type: null,
      institution: {
        abe: true
        // task_category_id: []
      },
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      },
      institutionRules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        // name: [
        //   {
        //     min: 6,
        //     required: true,
        //     message: "Please this field is required",
        //     trigger: "blur"
        //   }
        // ],
        promo_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        max_users: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        expiration_date: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],

        contact_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        phone_number: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      institution_payment: {},

      institution_dialog: false,
      show_institution_dialog: false,
      // single organization shown in the medium and small screens
      shown_institution: {}
    };
  },
  computed: {
    categories() {
      return this.$store.getters["organization/getCategories"];
    }
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("organization/organizations", { query: query });
    },
    // task_category_id
    remove(id, removed) {
      Swal.fire({
        text:
          removed == 0
            ? `${this.localization("Are You Sure")}`
            : `${this.localization("Are You Sure")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText:
          removed == 0
            ? `${this.localization("Delete")}`
            : `${this.localization("Activate")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("organization/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              // title: removed == 0 ? `Deleted` : "Actived",
              text:
                removed == 0
                  ? `${this.localization("Deleted")}`
                  : `${this.localization("Done")}`,
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
              confirmButtonText: `${this.localization("Ok")}`
            });
          });
        }
      });
    },
    open_institution_dialog(type, item) {
      this.institution = { ...item };
      this.type = type;
      this.institution_dialog = true;
    },
    open_show_institution_dialog(item) {
      this.shown_institution = Object.entries(item)
        .map(([key, value]) => {
          return { key, value };
        })
        .filter(
          item =>
            item.key !== "id" &&
            item.key !== "old_id" &&
            item.key !== "removed" &&
            item.key !== "isEnabled"
        );
      this.show_institution_dialog = true;
    },
    save() {
      // this indicate for ADD INSTITUTION form only not
      //  PAYMENT FORM, so after adding function for payment submition, check for both forms validation
      this.$refs["institutionRef"].validate(valid => {
        if (valid) {
          const requestInstitution = { ...this.institution };
          delete requestInstitution.remaining_seats;

          if (this.type == 1) {
            this.$store
              .dispatch("organization/saveNewOrganization", {
                query: requestInstitution
              })
              .then(_ => {
                this.Refresh({});
                this.institution_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("organization/saveOrganization", {
                id: this.institution.id,
                query: requestInstitution
              })
              .then(_ => {
                this.Refresh({});
                this.institution_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        } else {
          return false;
        }
      });
    },
    processPayment() {
      // this indicate for ADD INSTITUTION form only not
      //  PAYMENT FORM, so after adding function for payment submition, check for both forms validation

      // this.$refs["paymentRef"].validate((valid) => {
      //   if (valid) {
      if (this.type == 1) {
        this.$store
          .dispatch("organization/saveNewOrganization", {
            query: this.institution_payment
          })
          .then(_ => {
            this.Refresh({});
            this.institution_dialog = false;
            Swal.fire({
              title: "",
              icon: "success",
              text: `${this.localization("Done")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              type: "success",
              confirmButtonClass: "btn btn-success ",
              buttonsStyling: false
            });
          });
      } else {
        this.$store
          .dispatch("organization/saveOrganization", {
            id: this.institution.id,
            query: this.institution_payment
          })
          .then(_ => {
            this.Refresh({});
            this.institution_dialog = false;
            Swal.fire({
              title: "",
              icon: "success",
              text: `${this.localization("Done")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              type: "success",
              confirmButtonClass: "btn btn-success ",
              buttonsStyling: false
            });
          });
      }
      // } else {
      //   return false;
      // }
      // });
    },
    Export(query) {
      this.$store.dispatch("organization/Export", { query: query });
    },
    reset_filter() {
      this.filter = {};
      this.Refresh({ removed: 0 });
    }
  }
};
</script>

<style scoped lang="scss">
.part_one_card {
  border-right: 1px solid #dcdfe6;
}
.part_two_card {
  padding-left: 8px !important;
}
.container--fluid {
  min-height: 80vh !important;
}

.el-table--border,
.el-table--group {
  border: unset;
}

.el-input .el-input--suffix {
  margin-right: 20px !important;
}
::v-deep .el-form-item__content {
  line-height: normal !important;
}
::v-deep .el-table .cell {
  padding: 0;
}
// check box and labels
::v-deep .el-form-item__label {
  padding: 0;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
  left: 7px;
  top: 3px;
}
::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
// end

::v-deep .el-table .cell .td-actions {
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ape {
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #e4e7ed;
  &.is-ape {
    background-color: #67c23a;
  }
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    // background-color: #23ccef;
    border-color: #00563f;
    background-color: #00563f;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>

<style lang="scss">
.main_institutions_wrapper {
  .el-dialog__body {
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 30px 5px;
    }
  }
}
</style>
